import { mutations, state } from '@/store'
import * as constants from '@/store/constants.js'
//import { testData } from '@/store/test_data.js'
import { sendRequest } from '@/lib/gms/api.js'
import { deepCopy } from '@/lib/gms/utils.js'

/**
 * EXAMPLE
 */
//import i18n from "@/i18n"
//i18n.t("Úspěšně uloženo")

export const defaultsRezervaceHost = {
    /**
     * Výchozí struktura pro entitu "rezervace_host"
     */
    rezervace_host: {
        rezervace_host_id: null,
        rezervace_pokoj_id:	null,
        host_kategorie_id: null,
        prijmeni: null,
        jmeno: null,
        email: null,
        tel: null,
        d_narozeni: null,
        cislo_dokladu: null,
        cislo_viza: null,
        zeme_id: null,
        ulice: null,
        mesto: null,
        psc: null,
        statni_prislusnost: null,
        ucel_pobytu_id: null,
        poznamka: null
    }
}

export const stateRezervaceHost = {
    /**
     * Data detailu hosta.
     * 
    *  @var {object}
    * 
        {
            "rezervace_host_id":"8",
            "pokoj_id":"11",
            "host_kategorie_id":"3",
            "prijmeni":"Stará ",
            "jmeno":"Petra",
            "email":null,
            "tel":null,
            "d_narozeni":null,
            "cislo_dokladu":"206874060",
            "cislo_viza":null,
            "zeme_id":"1",
            "zeme_nazev": "Česká republika",
            "ulice":"Pod vinicí",
            "mesto":null,
            "psc":null,
            "statni_prislusnost":"1",
            "ucel_pobytu_id":"1",
            "poznamka":null
        }
     */
    host_data: defaultsRezervaceHost.rezervace_host,

    /**
     * Chyby v datech detailu hosta.
     * 
     *  @var {object}
     */
    host_data_errors: defaultsRezervaceHost.rezervace_host
}

export const mutationsRezervaceHost = {
    setHostDetailData(data) {
        state.host_data = data
    },

    /**
     * Uloží data (nového nebo existujícího) hosta na server.
     * 
     * @return {Promise}
     */
     createOrUpdateHost(hostData) {
        return new Promise((resolve, reject) => {
            mutations.setDataStateHost(constants.DATA_STATE_PREPARING)

            state.host_data_errors = deepCopy(defaultsRezervaceHost.rezervace_host)

            hostData.rezervace_id_hash = state.request_rezervace_id

            sendRequest(
                '/api/save-guest',
                hostData
            )
            .then(response => {
                mutations.setDataStateHost(constants.DATA_STATE_READY)

                resolve(response.data)
            })
            .catch(errors => {
                mutations.setDataStateHost(constants.DATA_STATE_NOT_READY)

                reject(errors)
            })
        })
    },

    /**
     * Uloží data nového hosta na server.
     * 
     * @return {Promise}
     */
     OLDcreateHost(hostData) {
        return new Promise((resolve, reject) => {
            mutations.setDataStateHost(constants.DATA_STATE_PREPARING)

            state.host_data_errors = deepCopy(defaultsRezervaceHost.rezervace_host)

            hostData.rezervace_id_hash = state.request_rezervace_id

            sendRequest(
                '/api/create-guest',
                hostData
            )
            .then(response => {
                mutations.setDataStateHost(constants.DATA_STATE_READY)

                resolve(response.data)
            })
            .catch(errors => {
                mutations.setDataStateHost(constants.DATA_STATE_NOT_READY)

                reject(errors)
            })
        })
    },

    /**
     * Uloží data existujícího hosta na server.
     * 
     * @return {Promise}
     */
    OLDupdateHost(hostData) {
        return new Promise((resolve, reject) => {
            mutations.setDataStateHost(constants.DATA_STATE_PREPARING)

            state.host_data_errors = deepCopy(defaultsRezervaceHost.rezervace_host)

            hostData.rezervace_id_hash = state.request_rezervace_id

            sendRequest(
                '/api/update-guest',
                hostData
            )
            .then(response => {
                mutations.setDataStateHost(constants.DATA_STATE_READY)

                resolve(response.data)
            })
            .catch(errors => {
                mutations.setDataStateHost(constants.DATA_STATE_NOT_READY)

                reject(errors)
            })
        })
    },

    processHostDataErrors(errors) {
        errors.forEach(error => {
            state.host_data_errors[error.field] = 
                (state.host_data_errors[error.field] != null ? state.host_data_errors[error.field] + "<br>" : "")
                + error.message
        })
    }
}